import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GraphqlCollectorService} from '../http/graphql-collector.service';
import {SessionService} from '../session.service';
import {UserService} from './user.service';
import {GraphQLQuery} from '../../util/graphql-executor';
import {EnvironmentService} from '../environment.service'


@Injectable({
  providedIn: 'root'
})
export class KeyValueService {
  private repoUrlUser: string
  private repoUrl: string

  constructor(private httpClient: HttpClient,
              private graphQLService: GraphqlCollectorService,
              private sessionService: SessionService,
              private userService: UserService,
              environmentService: EnvironmentService) {
    this.repoUrl = `${environmentService.environment.serverUrl}/uistore`
    this.repoUrlUser = `${environmentService.environment.serverUrl}/uistore/user`

    sessionService.loginData$.subscribe(loginData => {
      if (loginData.isValid) {
        this.requestAll();
      }
    });
  }

  private allSubject$ = new BehaviorSubject<KeyValue[]>(undefined);

  get all$(): Observable<KeyValue[]> {
    return this.allSubject$;
  }

  get all(): KeyValue[] {
    return this.allSubject$.getValue();
  }

  static allGraphsQLQuery(): GraphQLQuery {
    return {
      function: 'allKeyValues',
      variables: [],
      fieldBody: KEY_VALUE_ENTITY_TEMPLATE
    };
  }

  requestAll(): void {
    this.graphQLService
      .query(KeyValueService.allGraphsQLQuery())
      .subscribe(response => this.allSubject$.next(response));
  }

  get(key: string): any {
    return this.all.find(keyValue => keyValue.key === key);
  }

  update(keyValue: KeyValue): void {
    keyValue.userFK = this.userService.me.id;
    this.httpClient.put(`${this.repoUrlUser}/${keyValue.key}`, keyValue).subscribe(() => {
      this.requestAll();
    });
  }

  updateSystemWide(keyValue: KeyValue): void {
    if (keyValue.userFK == null) {
      this.httpClient.put(`${this.repoUrl}/${keyValue.key}`, keyValue).subscribe(() => {
        this.requestAll();
      });
    } else {
      this.update(keyValue);
    }
  }

  create(key: string, value: any): void {
    this.httpClient.post(this.repoUrlUser, {
      userFK: this.userService.me.id,
      name: key,
      value
    }).subscribe(() => this.requestAll());
  }

  delete(key: string): void {
    this.httpClient.delete(`${this.repoUrlUser}/${key}`).subscribe(() => this.requestAll());
  }

}

export interface KeyValue {
  userFK: number;
  key: string;
  value: string;
}

export const KEY_VALUE_ENTITY_TEMPLATE =
  `
    key,
    value,
  `;
