import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {TimeNavigationService} from '../time-navigation.service'
import {DateUtils} from '../../util/date-utils'
import {GraphqlCollectorService} from '../http/graphql-collector.service'
import {createVariable, GraphQLQuery} from '../../util/graphql-executor'
import {map, take} from 'rxjs/operators'
import {
  AbsenceEntryDeleteBulkPayload,
  AbsenceEntryDeleteResult,
  AbsenceEntryMutationError,
  AbsenceEntryMutationResult,
  AbsenceEntryUpsertBulkPayload,
  AbsenceEntryUpsertResult,
  ModificationResult,
  splitMutationResult
} from './graphql/graphql-types'
import {FetchedMonthSubmittedData, FetchedMonthsubmittedDataService} from '../fetched-monthsubmitted-data.service'
import {SubmitMonthService} from './submit-month.service'
import {EnvironmentService} from '../environment.service'

const ME = '0'

@Injectable({
  providedIn: 'root'
})
export class AbsenceEntriesService {
  private repoUrl: string
  private repoUrlMe: string


  constructor(private graphQLService: GraphqlCollectorService,
              private timeNavigationService: TimeNavigationService,
              private fetchedMonthSubmittedDataService: FetchedMonthsubmittedDataService,
              private httpClient: HttpClient,
              private submitMonthService: SubmitMonthService,
              environmentService: EnvironmentService) {
    this.repoUrl = `${environmentService.environment.serverUrl}/absenceEntries`
    this.repoUrlMe = `${environmentService.environment.serverUrl}/absenceEntries/me`
  }

  static allUnsubmittedAbsenceEntriesQuery(userId: string, forceFetchMonths: string[]): GraphQLQuery {
    return {
      function: 'monthsSubmittedAll',
      variables: [
        createVariable('userId', 'Int', userId),
        createVariable('submitTypes', '[SubmitType!]', ['Open', 'Reopened']),
        createVariable('forceFetchMonths', '[String!]', forceFetchMonths)
      ],
      fieldBody: MONTHS_SUBMITTED_ABSENCE_ENTRY_ENTITY_TEMPLATE
    }
  }

  private static absenceInsertUpdateMutation(entities: AbsenceEntryEntity[]): GraphQLQuery {
    return {
      function: 'absenceEntriesBulk',
      variables: [
        createVariable('input', 'AbsenceEntryUpsertBulkInput!', {
          absenceEntries: entities
        })
      ],
      fieldBody: ABSENCE_ENTRY_ENTITY_BULK_TEMPLATE
    }
  }

  private static absenceDeleteMutation(entities: AbsenceEntryEntity[]): GraphQLQuery {
    return {
      function: 'absenceEntriesBulkDelete',
      variables: [
        createVariable('input', 'AbsenceEntryBulkDeleteInput!', {
          uuids: entities.map(entity => entity.id)
        })
      ],
      fieldBody: ABSENCE_ENTRY_ENTITY_BULK_DELETE_TEMPLATE
    }
  }

  async fetchAllUnsubmittedAbsenceEntries(): Promise<AbsenceEntryEntity[]> {
    const firstDayOfCurrentMonth = DateUtils.getFirstDayOfMonth(this.timeNavigationService.currentMonth)
    const previousMonth = DateUtils.getPreviousMonth(firstDayOfCurrentMonth)
    const nextMonth = DateUtils.getNextMonth(firstDayOfCurrentMonth)

    const holidays: AbsenceEntryEntity[] = []
    await this.fetchHolidays(previousMonth).then(result => holidays.push(...result))
    await this.fetchHolidays(firstDayOfCurrentMonth).then(result => holidays.push(...result))
    await this.fetchHolidays(nextMonth).then(result => holidays.push(...result))

    return new Promise<AbsenceEntryEntity[]>(resolve => {
      const forceFetchMonths = [
        DateUtils.dateToString(previousMonth),
        DateUtils.dateToString(firstDayOfCurrentMonth),
        DateUtils.dateToString(nextMonth),
      ]
      this.graphQLService.query(AbsenceEntriesService.allUnsubmittedAbsenceEntriesQuery(ME, forceFetchMonths)).subscribe(results => {
        const fetchedAbsenceEntryEntities = results.reduce((absenceEntryEntities: AbsenceEntryEntity[], month) => {
          return absenceEntryEntities.concat(month.absenceEntries)
        }, [] as AbsenceEntryEntity[])

        const fetchedAbsenceEntriesByDate = fetchedAbsenceEntryEntities.map((absenceEntry) => absenceEntry.date)
        fetchedAbsenceEntryEntities
          .push(
            ...holidays
              .filter(
                (holiday) => !fetchedAbsenceEntriesByDate.includes(holiday.date)
              )
          )

        resolve(fetchedAbsenceEntryEntities)
      }, error => {
        // Handle error
        console.log('Error fetching months:', error)
      })
    })
  }

  async fetchHolidays(month: Date): Promise<AbsenceEntryEntity[]> {
    return new Promise<AbsenceEntryEntity[]>(resolve => {
      let holidays = []
      if (this.submitMonthService.getSubmitTypeOfMonthOrNull(month) == null) {
        const params = new HttpParams()
          .append('month', String(month.getMonth() + 1))
          .append('year', month.getFullYear().toString())
        this.httpClient.get<AbsenceEntryEntity[]>(this.repoUrlMe, {params}).subscribe(result => {
          holidays = result
          resolve(holidays)
        })
      } else {
        resolve(holidays)
      }
    })
  }

  async pushEntitiesToRemote(entities: AbsenceEntryEntity[]): Promise<AbsenceEntryMutationResult> {
    const isValidAbsence = (entity: AbsenceEntryEntity) => entity.absenceTypeFK != null && entity.absenceTypeFK !== -1
    const bulks: AbsenceEntryEntity[][] = []
    const insertUpdateBulk: AbsenceEntryEntity[] = []
    const deleteBulk: AbsenceEntryEntity[] = []

    bulks.push(deleteBulk)
    bulks.push(insertUpdateBulk)

    entities.forEach(entity => {
      const index: number = +isValidAbsence(entity)
      bulks[index].push(entity)
    })

    return new Promise<AbsenceEntryMutationResult>(resolve => {
        return Promise.all([
          this.bulkOperation(insertUpdateBulk, AbsenceEntriesService.absenceInsertUpdateMutation),
          this.bulkOperation(deleteBulk, AbsenceEntriesService.absenceDeleteMutation)
        ]).then((allResult) => {
            const insertUpdateResult = splitMutationResult<AbsenceEntryUpsertResult, AbsenceEntryMutationError>(allResult[0].result)

            const insertUpdateValidResults = insertUpdateResult.objects
            const insertUpdateErrors = insertUpdateResult.errors

            const deleteResult = splitMutationResult<AbsenceEntryDeleteResult, AbsenceEntryMutationError>(allResult[1].result)
            const errors = insertUpdateErrors.concat(deleteResult.errors)

            insertUpdateValidResults
              .filter((data) => {
                return data.monthSubmittedId != null
              }).forEach((data) => {
              this.fetchedMonthSubmittedDataService.delegateFetchedMonthSubmittedData({
                monthId: data.monthSubmittedId,
                month: data.month
              } as FetchedMonthSubmittedData)
            })

            resolve({
              result: (errors.length === 0) ? ModificationResult.SUCCESS : ModificationResult.WARNING,
              errors
            })
          },
          () => {
            resolve({
              result: ModificationResult.ERROR,
              errors: undefined
            })
          })
      }
    )
  }

  put(entity: AbsenceEntryEntity): Promise<ModificationResult> {
    return this.httpClient
      .put(this.repoUrlMe + '/' + entity.id, entity, {observe: 'response'})
      .pipe(
        map((response) => {
          this.fetchedMonthSubmittedDataService.evaluateResponseHeaders(response.headers, entity.date)
          return ModificationResult.SUCCESS
        }, () => {
          return ModificationResult.ERROR
        })
      ).toPromise()
  }

  delete(entryId: string): Promise<ModificationResult> {
    return this.httpClient
      .delete(`${this.repoUrl}/${entryId}`, {observe: 'response'})
      .pipe(
        map((response) => {
          this.fetchedMonthSubmittedDataService.evaluateResponseHeaders(response.headers, null)
          return ModificationResult.SUCCESS
        }, () => {
          return ModificationResult.ERROR
        })
      ).toPromise()
  }

  private async bulkOperation(bulk: AbsenceEntryEntity[], op: (bulk: AbsenceEntryEntity[]) => GraphQLQuery):
    Promise<AbsenceEntryUpsertBulkPayload | AbsenceEntryDeleteBulkPayload> {
    if (bulk.length !== 0) {
      return this.graphQLService.mutation(
        op(bulk)
      ).pipe(
        take(1)
      ).toPromise()
    }

    return {
      result: []
    }
  }
}


export interface AbsenceEntryEntity {
  id?: string
  userFK: number
  absenceTypeFK: number
  date: string
  name?: string
}

export const ABSENCE_ENTRY_ENTITY_TEMPLATE =
  `
    id,
    absenceTypeFK,
    userFK,
    date,
    name
 `

const MONTHS_SUBMITTED_ABSENCE_ENTRY_ENTITY_TEMPLATE =
  `
    month,
    absenceEntries {
      ${ABSENCE_ENTRY_ENTITY_TEMPLATE}
    }
  `

const ABSENCE_ENTRY_ENTITY_BULK_TEMPLATE =
  `
  result {
      valueOrError {
        ... on AbsenceEntryUpsertResult {
          id
          monthSubmittedId
          month
        }
        ... on AbsenceEntryMutationError {
          id
          status
        }
      }
    }
 `

const ABSENCE_ENTRY_ENTITY_BULK_DELETE_TEMPLATE =
  `
    result {
      valueOrError {
        ... on AbsenceEntryDeleteResult {
          id
        }
        ... on AbsenceEntryMutationError {
          id
          status
        }
      }
    }
 `
