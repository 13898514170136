import {EventEmitter, Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {InterceptableSubject, Interceptor} from '../util/interceptable-subject'
import {HttpClient} from '@angular/common/http'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {LoginDialogComponent, LoginDialogData} from '../shared-components/login-dialog/login-dialog.component'
import {InteractionStatus} from '@azure/msal-browser'
import {
    MsalBroadcastService,
    MsalCustomNavigationClient,
    MsalService
} from '@azure/msal-angular'
import {Router} from '@angular/router'
import {Location} from '@angular/common'
import {filter, first} from 'rxjs/operators'
import {EnvironmentService} from './environment.service'


@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private dialogRef: MatDialogRef<LoginDialogComponent>

    waitingForLogout = new EventEmitter<void>()
    private dialogOpen = false
    private repoURL: string

    constructor(private msalBroadcastService: MsalBroadcastService,
                private authService: MsalService,
                private router: Router,
                private location: Location,
                private httpClient: HttpClient,
                private dialog: MatDialog,
                environmentService: EnvironmentService) {
        const customNavigationClient = new MsalCustomNavigationClient(authService, this.router, this.location)
        this.authService.instance.setNavigationClient(customNavigationClient)
        this.repoURL = `${environmentService.environment.serverUrl}/check`

        this.onLogout$.subscribe(() => {
            this.redirectToAzureLogout()
        })
    }

    private _onLogout$ = new InterceptableSubject<void>()

    get onLogout$(): Observable<any> {
        return this._onLogout$
    }

    private _loginData$ = new BehaviorSubject<LoginData>({email: '', isValid: false})

    get loginData$(): Observable<LoginData> {
        return this._loginData$
    }

    get loginData(): LoginData {
        return this._loginData$.getValue()
    }

    addLogoutInterceptor(interceptor: Interceptor<void>) {
        this._onLogout$.addInterceptor(interceptor)
    }

    checkIfLoggedIn() {
        this.httpClient.get(`${this.repoURL}`).subscribe(result => {
        })
    }

    showLoginDialog() {
        if (!this.dialogOpen) {
            this.dialogRef = this.dialog.open(LoginDialogComponent, {
                disableClose: true,
                data: {} as LoginDialogData,
            })
            this.dialogRef.afterOpened().subscribe(() => {
                this.dialogOpen = true
            })
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == LoginDialogComponent.TO_LOGIN_SCREEN) {
                    this.logoutNotAuthorizedUser()
                } else if (result == LoginDialogComponent.RESULT_RELOG) {
                    console.log('acquire Refresh token here')
                    this.login()
                }
            })
        }
    }

    /**
     * Log in to Azure AD
     * @returns the email address of the logged-in user
     */
    login() {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) => status === InteractionStatus.None
                ),
                first()
            )
            .subscribe(() => {
                const isAuthed = this.authService.instance.getAllAccounts().length > 0

                if (!isAuthed) {
                    this.authService.loginRedirect()
                } else {
                    const account = this.authService.instance.getAllAccounts()[0]
                    this._loginData$.next({
                        email: account.username,
                        isValid: account.username?.length > 0,
                    })
                }
            })
    }

    logout() {
        this.waitingForLogout.next()
        this._onLogout$.next()
    }

    logoutNotAuthorizedUser() {
        this.redirectToAzureLogout()
    }

    private redirectToAzureLogout() {
        this.authService.logoutRedirect()
    }

}

export interface LoginData {
    email: string
    isValid: boolean
}

