import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GraphqlCollectorService} from '../http/graphql-collector.service';
import {SessionService} from '../session.service';
import {createVariable, GraphQLQuery} from '../../util/graphql-executor';
import {EnvironmentService} from '../environment.service'


@Injectable({
  providedIn: 'root'
})
export class AssignmentService {

  private allSubject$ = new BehaviorSubject<AssignmentEntity[]>(undefined);
  private repoURL: string

  constructor(private httpClient: HttpClient,
              private graphQLService: GraphqlCollectorService,
              private sessionService: SessionService,
              environmentService: EnvironmentService) {
    this.repoURL = `${environmentService.environment.serverUrl}/assignments`

    sessionService.loginData$.subscribe(loginData => {
      if (loginData.isValid) {
        this.requestAll();
      }
    });
  }

  get all$(): Observable<AssignmentEntity[]> {
    return this.allSubject$;
  }

  get all(): AssignmentEntity[] {
    return this.allSubject$.value;
  }

  static allGraphsQLQuery(): GraphQLQuery {
    return {
      function: 'assignments',
      variables: [],
      fieldBody: ASSIGNMENT_TEMPLATE
    };
  }

  static byIdGraphsQLQuery(id: number): GraphQLQuery {
    return {
      function: 'assignment',
      variables: [
        createVariable('id', 'Int!', id)
      ],
      fieldBody: ASSIGNMENT_TEMPLATE
    };
  }

  requestAll(): void {
    this.graphQLService
      .query(AssignmentService.allGraphsQLQuery())
      .subscribe(response => {
        this.allSubject$.next(response);
      });
  }

  /* APIs below are not yet converted to BloC pattern */

  get(id: number): Observable<AssignmentEntity> {
    return this.graphQLService.query(AssignmentService.byIdGraphsQLQuery(id));
  }

  update(assignment: AssignmentEntity): void {
    this.httpClient.put(`${this.repoURL}/${assignment.id}`, assignment).subscribe(() => this.requestAll());
  }

  create(newAssignment: AssignmentEntity): void {
    this.httpClient.post(this.repoURL, newAssignment).subscribe(() => this.requestAll());
  }

  delete(assignment: AssignmentEntity): void {
    assignment.archived = true;
    this.update(assignment);
  }

  restore(assignment: AssignmentEntity): void {
    assignment.archived = false;
    this.update(assignment);
  }


}

export interface AssignmentEntity {
  id?: number;
  name: string;
  description: string;
  contractNumber: string;
  customFK: number;
  archived?: boolean;
  responsibleSupervisorFK?: number;
}

export const ASSIGNMENT_TEMPLATE =
  `
    id,
    name,
    description,
    contractNumber,
    customFK,
    archived,
    responsibleSupervisorFK
 `;
