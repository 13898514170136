import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {EnvironmentService} from '../environment.service'



@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private images = new Map<string, ImageEntry>();
  private imagePromises = new Map<string, Promise<ImageEntry>>();
  private repoURL: string

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    environmentService: EnvironmentService) {
    this.repoURL = `${environmentService.environment.serverUrl}/imagestore`
  }

  async getImage(imageName: string): Promise<ImageEntry> {
    return this.loadOrRequestImage(imageName);
  }

  async getDecodedImage(imageName: string): Promise<any> {
    const imageEntry = await this.loadOrRequestImage(imageName);
    return this.decodeImageArrayBuffer(imageEntry.image);
  }

  async uploadImage(imageName, imageData: Uint8Array) {
    return new Promise<any>(async (resolve, reject) => {

      if (!imageData || !imageName) {
        reject();
        return;
      }

      const timestamp = new Date().getTime();
      const newName = `${imageName}_${timestamp}`;
      this.httpClient.put(`${this.repoURL}/${newName}`, {
        imageName: newName,
        image: Array.from(imageData) // Convert to array for server
      }).subscribe(resp => {
        const imageEntry = this.createNewImageEntry(newName, imageData);
        this.attachImageEntryToLocalCache(imageEntry);
        resolve(imageEntry);
      }, error => {
        reject(error);
      });
    });
  }

  sanitizeImage(image: string): any {
    return this.sanitizer.bypassSecurityTrustUrl(image);
  }

  decodeImageArrayBuffer(arrayBuffer: Uint8Array, mimeType?: string): any {
    const imageAsString = btoa(String.fromCharCode.apply(null, arrayBuffer));
    return this.sanitizeImage(`data:${mimeType || this.extractMimeType(arrayBuffer)};base64,${imageAsString}`);
  }

  async decodeImageFile(imageFile: File): Promise<{ arrayBuffer: Uint8Array, sanitziedUrl: SafeUrl }> {
    const arrayBuffer = await this.convertImageFileToIntArray(imageFile);
    const sanitizedUrl = this.decodeImageArrayBuffer(arrayBuffer, imageFile.type);
    return {
      arrayBuffer,
      sanitziedUrl: sanitizedUrl
    };
  }

  private extractMimeType(arrayBuffer: Uint8Array): string {
    const signature =
      Array.from(arrayBuffer.subarray(0, 4))
        .map((entry) => entry.toString(16))
        .join('');

    switch (signature) {
      case '89504e47':
        return 'image/png';
      case '3c737667':
      case '3c3f786d':
        return 'image/svg+xml';
      case '47494638':
        return 'image/gif';
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
        return 'image/jpeg';
    }
  }

  private async convertImageFileToIntArray(imageFile: File): Promise<Uint8Array> {
    return new Promise<Uint8Array>((resolve) => {
      const dataUrlReader = new FileReader();

      dataUrlReader.onload = (_event) => {
        const array = new Uint8Array(dataUrlReader.result as ArrayBuffer);
        resolve(array);
      };
      dataUrlReader.readAsArrayBuffer(imageFile);
    });
  }

  private async loadOrRequestImage(imageName: string): Promise<ImageEntry> {
    if (imageName == null || imageName === '') {
      return {
        image: new Uint8Array(0),
        imageName: imageName
      };
    } else if (this.images.has(imageName)) {
      return this.images.get(imageName);
    } else {
      if (this.imagePromises.has(imageName)) {
        return this.imagePromises.get(imageName);
      }

      const imagePromise = new Promise<ImageEntry>(resolve => {
        this.requestImage(imageName).subscribe(response => {
          const newImage = this.createNewImageEntry(response.imageName, new Uint8Array(response.image));
          this.attachImageEntryToLocalCache(newImage);
          resolve(newImage);
        });
      });

      this.imagePromises.set(imageName, imagePromise);
      return imagePromise;
    }
  }

  private requestImage(imageName: string): Observable<ImageEntry> {
    return this.httpClient.get(`${this.repoURL}/${imageName}`) as Observable<ImageEntry>;
  }

  private attachImageEntryToLocalCache(imageEntry: ImageEntry): void {
    this.images.set(imageEntry.imageName, imageEntry);
    this.imagePromises.delete(imageEntry.imageName);
  }

  private createNewImageEntry(imageName: string, imageArrayBuffer: Uint8Array): ImageEntry {
    return {
      imageName: imageName,
      image: imageArrayBuffer
    } as ImageEntry;
  }

}


export interface ImageEntry {
  image: Uint8Array;
  imageName: string;
}
