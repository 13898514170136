import {Injectable} from '@angular/core'

export type Stage = 'local' | 'abnahme' | 'integration' | 'produktion'

export interface AppConfig {
  environment: Stage
}


declare global {
  interface Window {
    appConfig: AppConfig
  }
}


export interface EnvironmentProfile {
  readonly production: boolean
  readonly serverUrl: string
  readonly clientId: string
  readonly maxNoteLength: number
  readonly maxCommentLength: number
}

const profiles: { [key: string]: EnvironmentProfile } = {
  local: {
    production: false,
    serverUrl: 'http://localhost:7000',
    clientId: '20a2a4cd-9f45-4c42-886b-e75a29c03d1b',
    maxNoteLength: 500,
    maxCommentLength: 250,
  },
  integration: {
    production: false,
    serverUrl: 'https://compeople-timebox-integration.azurewebsites.net',
    clientId: 'ce31879d-aecd-4251-b286-904c74519557',
    maxNoteLength: 300,
    maxCommentLength: 250,
  },
  abnahme: {
    production: true,
    serverUrl: 'https://compeople-timebox-abnahme-server.azurewebsites.net',
    clientId: 'df979ca1-2f93-4704-9401-d2951edb6e44',
    maxNoteLength: 500,
    maxCommentLength: 250,
  },
  produktion: {
    production: true,
    serverUrl: 'https://compeople-timebox-server-production.azurewebsites.net',
    clientId: '8ae9a04d-42a2-4f0d-b4ec-39d840fa184b',
    maxNoteLength: 500,
    maxCommentLength: 250,
  }
} as const


@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  public get environment(): EnvironmentProfile {
    return profiles[window.appConfig.environment]
  }

}

export const environment =  {
   get production(): boolean {
    return profiles[window.appConfig.environment].production
  },
  get serverUrl(): string {
    return profiles[window.appConfig.environment].serverUrl
  },
  get clientId(): string {
    return profiles[window.appConfig.environment].clientId
  },
  get maxNoteLength(): number {
    return profiles[window.appConfig.environment].maxNoteLength
  },
  get maxCommentLength(): number {
    return profiles[window.appConfig.environment].maxCommentLength
  }
}


