import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {SessionService} from '../session.service'
import {UserService} from './user.service'
import packageJson from '../../../../package.json'
import {EnvironmentService} from '../environment.service'


@Injectable({
  providedIn: 'root'
})
export class VersionInfoService {

  public timeboxAppVersion: string
  readonly timeboxAppBuildVersion = 'cv_1.3.1'
  private repoURL: string

  constructor(private httpClient: HttpClient,
              private userService: UserService,
              environmentService: EnvironmentService,
              private sessionService: SessionService) {
    this.repoURL = `${environmentService.environment.serverUrl}/serverinfo`

    this.timeboxAppVersion = packageJson.version

    this.sessionService.loginData$.subscribe(data => {
      if (data != undefined && data.isValid) {
        this.requestInfo().subscribe(result => this._info$.next(result))
      }
    })
  }

  private _info$ = new BehaviorSubject<ServerInfo>(null)

  get info$(): Observable<ServerInfo> {
    return this._info$
  }

  get info(): ServerInfo {
    return this._info$.getValue()

  }


  private requestInfo(): Observable<ServerInfo> {
    return this.httpClient.get(this.repoURL) as Observable<ServerInfo>
  }

}

export interface ServerInfo {
  commit: string,
  version: string,
  time: string,
  build: string
}
