import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable} from 'rxjs'
import {GraphqlCollectorService} from '../http/graphql-collector.service'
import {SessionService} from '../session.service'
import {createVariable, GraphQLQuery} from '../../util/graphql-executor'
import {deepCopy} from 'src/app/util/copy'
import {ShaHashing} from '../../util/sha-hashing'
import {EnvironmentService} from '../environment.service'

const GRAPH_SQL_STRING = {
    variables: [],
    function: 'me',
    fieldBody:
        `user {
          id,
          firstName,
          lastName,
          companyName,
          archived,
          adRef,
          email,
          initial,
          status,
          timeSheetStatus,
          workStart
        },
        roles,
        permissions`
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
  private repoURL: string

    constructor(private httpClient: HttpClient,
                private graphsqlService: GraphqlCollectorService,
                environmentService: EnvironmentService,
                private sessionService: SessionService) {
        this.repoURL = `${environmentService.environment.serverUrl}/users`
        sessionService.loginData$.subscribe(loginData => {
            if (loginData.isValid) {
                this.requestMe()
                this.requestPictureOfMe()
            }
        })
    }

    private _pictureOfMe$ = new BehaviorSubject<string>('')

    get pictureOfMe$(): Observable<string> {
        return this._pictureOfMe$
    }

    private _me$ = new BehaviorSubject<User>(undefined)

    get me$(): Observable<User> {
        return this._me$
    }

    private _all$ = new BehaviorSubject<User[]>(undefined)

    get all$(): Observable<User[]> {
        return this._all$
    }

  private _allSupervisor$ = new BehaviorSubject<User[]>(undefined)


  get allSupervisor$(): Observable<User[]> {
    return this._allSupervisor$
  }

  private _allAssignmentSupervisor$ = new BehaviorSubject<User[]>(undefined)

  get allAssignmentSupervisor$(): Observable<User[]> {
    return this._allAssignmentSupervisor$
  }
    get me(): User {
        return this._me$.getValue()
    }

    get all(): User[] {
        return this._all$.value
    }

  get allSupervisor(): User[] {
    return this._allSupervisor$.value
  }

    static allGraphsQLQuery(): GraphQLQuery {
        return {
            function: 'users',
            variables: [],
            fieldBody: USER_TEMPLATE
        }
    }

    static allSupervisorGraphQLQuery(roles: string[]): GraphQLQuery {
        return {
            function: 'usersByRole',
            variables: [
                createVariable('roles', '[String!]!', roles)
            ],
            fieldBody: USER_TEMPLATE
        }
    }

    requestAll(): Promise<User[]> {
        return new Promise<User[]>(resolve => {
            this.graphsqlService.query(UserService.allGraphsQLQuery()).subscribe((response) => {
                this._all$.next(response)
                resolve(response)
            }, error => {
                // Handle error
                console.log('Error fetching users:', error)
            })
        })
    }


    requestSupervisors(): Promise<User[]> {
        return new Promise<User[]>(resolve => {
                this.graphsqlService.query(UserService.allSupervisorGraphQLQuery(['TimeBoxPV'])).subscribe((response) => {
                  this._allSupervisor$.next(response)
                  resolve(response)
                }, error => {
                    console.log('Error fetching supervisors:', error)
                })
            }
        )
    }

  requestAssignmentSupervisors(): Promise<User[]> {
    return new Promise<User[]>(resolve => {
        this.graphsqlService.query(UserService.allSupervisorGraphQLQuery(['TimeBoxPV', 'TimeBoxPL'])).subscribe((response) => {
          this._allAssignmentSupervisor$.next(response)
          resolve(response)
        }, error => {
          console.log('Error fetching supervisors:', error)
        })
      }
    )
  }

    requestMe(): void {
        this.graphsqlService.query(GRAPH_SQL_STRING).subscribe((me: MeResponse) => {
            const user = createUser(me)
            this._me$.next(user)
        }, (error) => {
            if (error.status === 0) {
                return
            }
            // return User with no permissions on error
            console.error('failed to fetch user, access will be denied', error)
            this._me$.next({
                firstName: 'unknown',
                id: -1,
                lastName: 'unknown',
                permissions: null,
                roles: null,
                initial: 'unknown',
                adRef: 'unknown',
                email: 'unknown',
                companyName: 'unknown',
                archived: false,
                status: UserStatus.ACTIVE,
                timeSheetStatus: TimeSheetStatus.REQUIRED,
                manager: 'unknown',
                workStart: '1970-01-01',
                supervisorFK: null
            } as RemoteUser)
        })

    }

    isAnonym(userEntry: User): boolean {
        return userEntry.status === UserStatus.ANONYM
    }

    anonymizeUser(user: User): void {
        user.firstName = this.anonymousSha(user.firstName)
        user.lastName = this.anonymousSha(user.lastName)
        user.companyName = this.anonymousSha(user.companyName)
        user.adRef = this.anonymousSha(user.adRef)
        user.email = this.anonymousSha(user.email)
        user.initial = this.anonymousSha(user.initial)
        user.status = UserStatus.ANONYM
        user.timeSheetStatus = TimeSheetStatus.OPTIONAL
        this.httpClient.put(`${this.repoURL}/${user.id}`, toRemoteUser(user)).subscribe(() => this.requestAll())
    }

    private anonymousSha(userString: string): string {
        return ShaHashing.sha512(userString + (new Date()).getTime().toString())
    }

    editUser(user: User): void {
        this.httpClient.put(`${this.repoURL}/${user.id}`, toRemoteUser(user)).subscribe(() => this.requestAll())
    }

    editEmail(email: string): void {
        this.httpClient.post(`${this.repoURL}/me/email`, email).subscribe(() => this.requestMe())
    }

    requestPictureOfMe(): void {
        this.loadImageBinaryFromServer(`${this.repoURL}/me/photo`).subscribe(event => {
            this.receiveBinaryImage(event)
        }, error => {
        })
    }

    hasApplicationAccess(user: User): boolean {
        return hasPermission(user, Permission.AppUsagePermission)
    }


    getUserById(id: number): User {
        return deepCopy(this.all.find(user => {
            return user.id === id
        }))
    }

    setUsersTimeSheetStatus(employeeId: number, status: TimeSheetStatus): void {
        const employee = this.getUserById(employeeId)
        employee.timeSheetStatus = status
        this.editUser(employee)
    }


    private loadImageBinaryFromServer(imageUrl: string): Observable<Blob> {
        return this.httpClient.get(imageUrl, {responseType: 'blob'})
    }

    private receiveBinaryImage(image: Blob): void {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            this._pictureOfMe$.next(reader.result as string)
        }, false)

        if (image) {
            reader.readAsDataURL(image)
        }
    }

    getPermissionsOfUser(userId: number): Observable<Permission[]> {
        return this.httpClient.get<Permission[]>(`${this.repoURL}/${userId}/permissions`)
    }
}

interface MeResponse {
    user: User
    roles: Role[]
    permissions: Permission[]
}

export interface User extends RemoteUser {
    supervisor?: User
}

export interface RemoteUser {
    id: number
    firstName: string
    lastName: string
    companyName: string
    archived: boolean
    adRef: string
    email: string
    initial: string
    status: UserStatus
    timeSheetStatus: TimeSheetStatus
    roles: Role[]
    permissions: Permission[]
    workStart: string
    supervisorFK: number
}

function toRemoteUser(user: User): RemoteUser {
    return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: user.companyName,
        archived: user.archived,
        adRef: user.adRef,
        email: user.email,
        initial: user.initial,
        status: user.status,
        timeSheetStatus: user.timeSheetStatus,
        roles: user.roles,
        permissions: user.permissions,
        workStart: user.workStart,
        supervisorFK: (user.supervisor != null) ? user.supervisor.id : null
    }
}

function createUser(meResponse: MeResponse): User {
    const user = meResponse.user
    user.roles = meResponse.roles
    user.permissions = meResponse.permissions
    return user
}

export enum UserStatus {
    ACTIVE = 'Active',
    PAUSED = 'Paused',
    ANONYM = 'Anonym'
}

export enum TimeSheetStatus {
    REQUIRED = 'Required',
    OPTIONAL = 'Optional'
}

export enum TimeSheetStatusNames {
    REQUIRED = 'Erforderlich',
    OPTIONAL = 'Optional'
}

export enum Role {
    TimeboxAngestellte = 'TimeboxAngestellte',
    TimeBoxPV = 'TimeBoxPV',
    TimeboxApp = 'TimeboxApp',
    TimeBoxPL = 'TimeBoxPL',
    TimeboxAngestellteExtern = 'TimeboxAngestellteExtern'
}

export function hasPermission(user: User, permission: Permission): boolean {
    return (user.permissions != null) && (user.permissions.indexOf(permission) >= 0)
}

export function hasPermissions(user: User, permissions: Permission[]): boolean {
    return permissions.reduce<boolean>((acc: boolean, currentValue: Permission) => {
        return acc && hasPermission(user, currentValue)
    }, true)
}

export enum Permission {
    AppUsagePermission = 'AppUsagePermission',
    ProcessOtherUsersPermission = 'ProcessOtherUsersPermission',
    ProcessAbsenceEntriesPermission = 'ProcessAbsenceEntriesPermission',
    ProcessOtherUsersMonthsSubmittedPermission = 'ProcessOtherUsersMonthsSubmittedPermission',
    ProcessOtherUsersTimeEntriesPermission = 'ProcessOtherUsersTimeEntriesPermission',
    ProcessSelectionEntitiesPermission = 'ProcessSelectionEntitiesPermission',
    UnlimitedWorkingHoursPermission = 'UnlimitedWorkingHoursPermission',
    CheckMonthsSubmittedPermission = 'CheckMonthsSubmittedPermission',
    VerifyMonthsSubmittedPermission = 'VerifyMonthsSubmittedPermission',
    SubmitOtherUsersMonthsPermission = 'SubmitOtherUsersMonthsPermission',
    ProcessTopicsPermisson = 'ProcessTopicsPermisson',
    ProcessTimeTypesPermisson = 'ProcessTimeTypesPermisson',
    ChangeContactEmailPermission = 'ChangeContactEmailPermission',
    ProcessHolidaysPermission = 'ProcessHolidaysPermission',
    ProcessAssignmentSupervisorPermission = 'ProcessAssignmentSupervisorPermission'
}

export const USER_TEMPLATE =
    `
    id,
    firstName,
    lastName,
    companyName,
    archived,
    adRef,
    email,
    initial,
    status,
    timeSheetStatus,
    workStart,
    supervisor {
      id,
      firstName,
      lastName
    }
 `

export interface UserEntry extends User {
    name: string
    timeSheetStatusSort: number
    supervisorName: string
}

export function toUserEntry(user: User): UserEntry {
    return {
        ...user,
        name: user.lastName + ', ' + user.firstName,
        timeSheetStatusSort: mapSortStateToNumber(user.timeSheetStatus),
        supervisorName: user.supervisor != null ? user.supervisor.lastName + ', ' + user.supervisor.firstName : ''
    } as UserEntry
}

export function mapSortStateToNumber(timeSheetStatus: TimeSheetStatus): number {
    switch (timeSheetStatus) {
        case TimeSheetStatus.REQUIRED:
            return 1
        case TimeSheetStatus.OPTIONAL:
            return 2
        default:
            return 3
    }
}
