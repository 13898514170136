import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GraphqlCollectorService} from '../http/graphql-collector.service';
import {SessionService} from '../session.service';
import {createVariable, GraphQLQuery} from '../../util/graphql-executor';
import {DateUtils} from '../../util/date-utils';
import {TimeNavigationService} from '../time-navigation.service';
import {UserService} from './user.service';
import {DateRange} from '../../util/date-range-picker';
import {EnvironmentService} from '../environment.service'


@Injectable({
  providedIn: 'root'
})
export class TopicService {
  private repoURL: string

  constructor(private httpClient: HttpClient,
              private graphqlService: GraphqlCollectorService,
              private sessionService: SessionService,
              environmentService: EnvironmentService,
              private timeNavigationService: TimeNavigationService,
              private userService: UserService) {
    this.repoURL = `${environmentService.environment.serverUrl}/topics`
    sessionService.loginData$.subscribe(loginData => {
      if (loginData.isValid) {
        this.requestAll();
      }
    });
  }

  private _all$ = new BehaviorSubject<TopicEntity[]>(undefined);

  get all$(): Observable<TopicEntity[]> {
    return this._all$;
  }

  get all(): TopicEntity[] {
    return this._all$.value;
  }

  static allGraphsQLQuery(): GraphQLQuery {
    return {
      function: 'topics',
      variables: [],
      fieldBody: TOPIC_ENTITY_TEMPLATE
    };
  }

  static getTimeInfoGraphsQLQuery(topicId: number, userId: number = null, dateRange: DateRange = null): GraphQLQuery {

    let from = null;
    let to = null;
    if (userId === undefined) {
      userId = null;
    }
    if (dateRange != null) {
      from = `\\"${DateUtils.dateToString(dateRange.from)}\\"`;
      to = `\\"${DateUtils.dateToString(dateRange.to)}\\"`;
    }

    return {
      function: 'topic',
      variables: [
        createVariable('id', 'Int!', topicId)
      ],
      fieldBody: `
        timeInfo {
          booked(from: ${from} , to: ${to}),
          bookedByUser(userId: ${userId} , from: ${from} , to: ${to})
          users(from: ${from} , to: ${to})
        }
      `
    };
  }

  requestAll() {
    this.graphqlService.query(TopicService.allGraphsQLQuery())
      .subscribe(response => this._all$.next(response as TopicEntity[]));
  }

  /* APIs below are not yet converted to BloC pattern */

  get(id: number): Observable<TopicEntity> {
    this.graphqlService.query(TopicService.getTimeInfoGraphsQLQuery(id, this.userService.me.id)).subscribe((result) => {
      console.log(result);
    });
    return this.httpClient.get(`${this.repoURL}/${id}`) as Observable<TopicEntity>;
  }

  update(topic: TopicEntity) {
    this.httpClient.put(`${this.repoURL}/${topic.id}`, topic).subscribe(() => this.requestAll());
  }

  create(newTopic: TopicEntity) {
    this.httpClient.post(this.repoURL, newTopic).subscribe(() => this.requestAll());
  }

  delete(topic: TopicEntity) {
    topic.archivedDate = DateUtils.dateToString(DateUtils.now);
    this.httpClient.put(`${this.repoURL}/${topic.id}`, topic).subscribe(() => this.requestAll());
  }

  restore(topic: TopicEntity) {
    topic.archivedDate = null;
    this.httpClient.put(`${this.repoURL}/${topic.id}`, topic).subscribe(() => this.requestAll());
  }

  isTopicArchivedInCurrentMonthById(topicId: number): boolean {
    const topic = this.all.find(t => t.id == topicId);
    return this.isTopicArchivedInCurrentMonth(topic);
  }

  isTopicArchivedInCurrentMonth(topic: TopicEntity): boolean {
    if (topic != undefined && topic.archivedDate != undefined) {
      const archivedDate = DateUtils.stringToDate(topic.archivedDate);
      return DateUtils.isAfterOrSameMonth(this.timeNavigationService.currentMonth, archivedDate);
    }

    return false;
  }

  isTopicArchived(topic: TopicEntity): boolean {
    if (topic != undefined && topic.archivedDate != undefined) {
      return true;
    }

    return false;
  }

  getArchivedTopicsOfCurrentMonth(): TopicEntity[] {
    if (this.all == undefined) {
      return [];
    }

    return this.all.filter(topic => {
      const archivedDate = topic.archivedDate;

      if (archivedDate != null) {
        const actualArchivedDate = DateUtils.stringToDate(archivedDate);
        return DateUtils.isBeforeOrSameMonth(actualArchivedDate, this.timeNavigationService.currentMonth);
      }

      return false;
    });
  }

}

export interface TimeInfo {
  booked: number;
  bookedByUser: number;
  users: [number];
}

export interface TopicData {
  timeInfo: TimeInfo;
}



export interface TopicEntity {
  id?: number;
  name: string;
  description: string;
  assignmentFK: number;
  archivedDate?: string;
}

export const TOPIC_ENTITY_TEMPLATE =
  `
    id,
    name,
    description,
    assignmentFK,
    archivedDate
 `;

/*
// alternatively you can expand the assignment:

export const TOPIC_ENTITY_TEMPLATE =
  `{
    id,
    name,
    description,
    assignment${ASSIGNMENT_TEMPLATE},
    archivedDate
 }`
*/
