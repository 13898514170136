import { Injectable } from '@angular/core'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'
import {AppConfig} from './services/environment.service'

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  public init(): Promise<any> {
    return from(fetch('assets/config/config.json').then((response) => response.json()))
      .pipe(
        map((config: AppConfig) => {
          window.appConfig = config
          return config
        }),
      )
      .toPromise()
  }
}
