<div *ngIf="customer != null">
  <div>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="customer.name" [maxlength]="nameInputLength" matInput placeholder="Name">
      <mat-hint align="end" style="padding-right: 10px">{{customer.name.length}}
        /{{nameInputLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input [(ngModel)]="customer.description" [maxlength]="descriptionInputLength" matInput
             placeholder="Beschreibung">
      <mat-hint align="end" style="padding-right: 10px">{{customer.description.length}}
        /{{descriptionInputLength}}</mat-hint>
    </mat-form-field>
  </div>

  <div class="full-width">
    <div class="image-row">
      <div class="imageText">
        <div class="icon">
          <img [src]="image"
               alt="Customer logo"
               class="cell-logo"
               onerror="this.src='../../../assets/customer-logo-placeholder.png'">
        </div>
        <label *ngIf="imageText;else noImage">
          {{imageText}}
        </label>
      </div>
      <input hidden (change)="onImageSelected()" (click)="onSelectImage()" #fileInput type="file" id="file"
             accept="image/*">
      <button type="button" mat-raised-button (click)="fileInput.click()">
        Bild auswählen
        <app-speech-bubble *ngIf="fileSizeError !== undefined" class="file-size-error-tooltip"
                           [arrowPosition]="'top-right'" [error]="true">
          {{getErrorMessage()}}
        </app-speech-bubble>
      </button>
    </div>
  </div>

  <div class="button-bar">
    <button (click)="confirm()" color="primary" mat-raised-button>OK</button>
    <button (click)="cancel()" color="warn" mat-raised-button>Abbrechen</button>
  </div>
</div>

<ng-template #noImage>
<label>
  Bitte Bild auswählen
</label>
</ng-template>
