import {Observable, Subject} from 'rxjs'
import {DateUtils} from './date-utils'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {EnvironmentService} from '../services/environment.service'

export enum RequestUrgency {
  IMMEDIATELY = 0,
  NORMAL = 200
}

const INTERVAL_TIME = 50
const REQUEST_PREFIX = 'r'

export enum GraphQLType {
  QUERY = 'query',
  MUTATION = 'mutation'
}

export class GraphQLExecutor {

  private requests: GraphQLQueryRequest[] = []
  private checkPendingRequestsInterval: any
  private graphQlUrl: string

  constructor(private type: GraphQLType,
              private httpClient: HttpClient,
              environmentService: EnvironmentService) {
    this.graphQlUrl = `${environmentService.environment.serverUrl}/graphql`
  }

  private static buildQueryArguments(variables: GraphQLVariable[]): string {
    return variables.reduce((acc, currentVariable) => {
      const commaPrefix = (acc == '') ? '' : ', '
      return acc + `${commaPrefix}$${currentVariable.name}: ${currentVariable.type}`
    }, '')
  }

  private static buildVariables(variables: GraphQLVariable[]): string {
    const innerBody: string = variables.reduce((acc, currentVariable) => {
      const commaPrefix = (acc == '') ? '' : ',\n'
      return acc + `${commaPrefix}\t\\"${currentVariable.name}\\": ${currentVariable.value}`
    }, '')

    return `{\n${innerBody}\n}`
  }

  request(graphQLQuery: GraphQLQuery, urgency: RequestUrgency = RequestUrgency.NORMAL): Observable<any> {
    const responseSubject = new Subject<any>()

    const duplicateRequest = this.requests.find(value => {
      return isSameQuery(value.graphQLQuery, graphQLQuery)
    })

    if (duplicateRequest != null) {
      duplicateRequest.canWaitUntil = Math.min(duplicateRequest.canWaitUntil, this.now() + urgency)
      duplicateRequest.responseSubjects.push(responseSubject)
    } else {
      this.requests.push({
        graphQLQuery,
        responseSubjects: [responseSubject],
        canWaitUntil: this.now() + urgency
      })

      if (this.checkPendingRequestsInterval == null) {
        this.checkPendingRequestsInterval = setInterval(() => this.checkPendingRequests(), INTERVAL_TIME)
      }
    }
    this.checkPendingRequests()
    return responseSubject
  }

  private now() {
    return DateUtils.today.getTime()
  }

  private checkPendingRequests() {
    const now = this.now()
    const canEveryRequestWait = this.requests.filter(request => now >= request.canWaitUntil).length == 0
    if (!canEveryRequestWait) {
      const currentRequests = this.requests

      const headers = new HttpHeaders().set('Content-Type', 'application/json')
      this.httpClient.post(`${this.graphQlUrl}`, this.buildRequest(), {headers: headers}).subscribe(response => {
          this.handleResponse(response, currentRequests)
        },
        error => this.handleError(error, currentRequests)
      )
      this.resetRequestQueue()
    }
  }

  private resetRequestQueue() {
    this.requests = []
    window.clearInterval(this.checkPendingRequestsInterval)
    this.checkPendingRequestsInterval = null
  }

  private handleResponse(response: any, currentRequests: GraphQLQueryRequest[]) {
    currentRequests.forEach((request, index) => {
      const key = REQUEST_PREFIX + index
      const data = response.data[key]
      request.responseSubjects.forEach(subject => subject.next(data))
    })
  }

  private handleError(error: any, currentRequests: GraphQLQueryRequest[]) {
    currentRequests.forEach((request) => {
      request.responseSubjects.forEach(subject => subject.error(error))
    })
  }

  private buildRequest(): string {
    this.prepareRequestVariables()

    const allArgs: GraphQLVariable[] = this.getAllVariables()

    const args = GraphQLExecutor.buildQueryArguments(allArgs)
    const innerFunctions = this.requests.reduce((acc, currentRequest, index) => {
      const alias = REQUEST_PREFIX + index
      return acc + `\n${alias}: ${getGraphQLQueryAsString(currentRequest.graphQLQuery)}`
    }, '')

    const brackedArguments = (args != '') ? `(${args})` : ''

    const formattedQuery = this.type + `${brackedArguments} { ${innerFunctions} }`
    const variables = GraphQLExecutor.buildVariables(allArgs).replace(/\s/g, '')

    return `{\n\t"query": "${formattedQuery}",\n"variables": "${variables}"\n}`
  }

  private getAllVariables() {
    return this.requests.reduce((acc, request) => {
      acc.push(...request.graphQLQuery.variables)
      return acc
    }, [] as GraphQLVariable[])
  }

  private prepareRequestVariables() {
    this.requests.forEach((request, index) => {
      request.graphQLQuery.variables.forEach(variable => {
        const varPrefix = `var${index}_`
        variable.name = varPrefix + variable.name
      })
    })
  }
}

function getGraphQLQueryAsString(graphQLQuery: GraphQLQuery) {
  const args: string = graphQLQuery.variables.reduce((acc, currentVariable) => {
    const commaPrefix = (acc == '') ? '' : ','
    return acc + `${commaPrefix}${currentVariable.fieldName}: $${currentVariable.name}`
  }, '')

  const brackedArguments = (args != '') ? `(${args})` : ''

  const body = (graphQLQuery.fieldBody != '') ? `{${graphQLQuery.fieldBody}}` : ''
  return `${graphQLQuery.function}${brackedArguments}${body}`
}

export interface GraphQLQuery {
  function: string,
  variables: GraphQLVariable[],
  fieldBody: string
}

function isSameQuery(first: GraphQLQuery, second: GraphQLQuery) {
  return first.function == second.function
    && first.fieldBody.trim().toLowerCase() == second.fieldBody.trim().toLowerCase()
    && first.variables.length == second.variables.length
    && first.variables.every((variable, index) => {
      return isSameVariable(variable, second.variables[index])
    })
}

interface GraphQLQueryRequest {
  graphQLQuery: GraphQLQuery
  responseSubjects: Subject<any>[]
  canWaitUntil: number
}

export interface GraphQLVariable {
  name: string
  type: string
  value: string
  fieldName: string
}

function isSameVariable(first: GraphQLVariable, second: GraphQLVariable) {
  return first.name == second.name
    && second.type == first.type
    && first.value == second.value
    && first.fieldName == second.fieldName
}

export function createVariable(name: string, type: string = 'String', value?: any, fieldName: string = name): GraphQLVariable {
  return {
    name,
    type,
    value: toJson(value),
    fieldName
  }
}

export function toJson(value: any): string {
  return JSON.stringify(value).replace(/"/g, '\\"')
}
