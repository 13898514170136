<ng-container *ngIf="user != null">

  <div id="viewport">
    <div class="containerSpacer"></div>
    <div *ngIf="accessGranted; else accessDenied" id="container">
      <header id="nav">
        <div id="cp_logo">
          <img alt="compeople-logo" class="logo" src="../assets/compeople_logo_rgb.jpg">
        </div>

        <div id="menu">
            <span *ngFor="let tab of tabs">
              <a routerLink="{{tab.routeTo}}"
                 routerLinkActive="active">{{tab.displayName}}</a>
            </span>
        </div>
        <div id="spacer"></div>
        <app-user-release-notes></app-user-release-notes>
        <div id="notifications">
          <app-notification-bell></app-notification-bell>
        </div>
        <div id="user">
          <app-user-info></app-user-info>
        </div>
      </header>
      <div *ngIf="!waitingForLogout; else waiting" class="" id="outerContent">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="containerSpacer"></div>
  </div>
</ng-container>

<ng-template #waiting>
  <div class="waiting-screen">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #accessDenied>
  <app-no-access></app-no-access>
</ng-template>
