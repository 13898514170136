import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GraphQLExecutor, GraphQLQuery, GraphQLType, RequestUrgency} from '../../util/graphql-executor';
import {EnvironmentService} from '../environment.service'

@Injectable({providedIn: 'root'})
export class GraphqlCollectorService {

  private queryExecutor: GraphQLExecutor;
  private mutationExecutor: GraphQLExecutor;

  constructor(private httpClient: HttpClient,
              environmentService: EnvironmentService) {
    this.queryExecutor = new GraphQLExecutor(GraphQLType.QUERY, httpClient, environmentService);
    this.mutationExecutor = new GraphQLExecutor(GraphQLType.MUTATION, httpClient, environmentService);
  }

  query(graphQLQuery: GraphQLQuery, urgency: RequestUrgency = RequestUrgency.NORMAL): Observable<any> {
    return this.queryExecutor.request(graphQLQuery, urgency);
  }

  mutation(graphQLQuery: GraphQLQuery, urgency: RequestUrgency = RequestUrgency.NORMAL): Observable<any> {
    return this.mutationExecutor.request(graphQLQuery, urgency);
  }

}

